import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  DesktopContainer,
  LastStyledLink,
  MobileContainer,
  PhoneNumber,
  StyledDesktopLineIcon,
  StyledDesktopMailIcon,
  StyledDesktopNumberIcon,
  StyledDesktopSmsIcon,
  StyledDesktopWhatsAppIcon,
  StyledLink,
  StyledMyFavorites,
  StyledNavBar,
  WhiteLastStyledLink,
} from '@components/NavBar/style';
import { ReactComponent as MobileWhatsAppIcon } from '@assets/whatApp/mobile.svg';
import { ReactComponent as MobileNumberIcon } from '@assets/number/mobile.svg';
import { ReactComponent as MobileMailIcon } from '@assets/mail/mobile.svg';
import { ReactComponent as MobileSmsIcon } from '@assets/sms/mobile.svg';
import { ReactComponent as MobileLineIcon } from '@assets/line/mobile.svg';
import { PATHS } from '@routes';
import { links } from '@constants/links';
import WhiteHeart from '@assets/whiteHeart.svg';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { colorUsage } from '@stylesheet';
import Functions from '@utils/Functions';
import { LookbookProduct, Product, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';

interface Props {
  caRecommendationId?: string;
  isProductDetailPage?: boolean;
}

type IProductDetailPage = {
  productStyleColorCode: string;
}

export const DesktopNavBar: React.FunctionComponent<Props> = ({
  caRecommendationId,
  isProductDetailPage = false,
}) => {
  const navigate = useNavigate();
  const { advisor, products, recommendationType } = useSelector(getCaRecommendation) || {};
  const { productStyleColorCode } = useParams<IProductDetailPage>();
  let productDetailed: Product | undefined;
  if (recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
    const caProducts = products as Product[];
    productDetailed = caProducts.find((product) => product.styleColorCode === productStyleColorCode);
  }
  if (recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
    const caProducts = products as LookbookProduct[];
    caProducts.forEach((product) => {
      if (productDetailed === undefined) {
        const findedProduct = product.products.find((p) => p.styleColorCode === productStyleColorCode);
        if (findedProduct) {
          productDetailed = findedProduct;
        }
      }
    });
  }
  return (
    <DesktopContainer>
      <StyledNavBar>
        <StyledLink onClick={() => navigate(`/suggestion/${caRecommendationId}?page=list`)}>
          <FormattedMessage id="nav-bar.advisor" />
        </StyledLink>
        {advisor?.whatsApp && (
          <StyledLink href={advisor?.whatsApp} rel="noopener noreferrer" target="_blank">
            <StyledDesktopWhatsAppIcon />
            <FormattedMessage id="nav-bar.contact.whatsApp" />
          </StyledLink>
        )}
        {advisor?.workPhoneNumber && (
          <StyledLink rel="noopener noreferrer" target="_blank">
            <PhoneNumber className="phone-number">{advisor?.workPhoneNumber}</PhoneNumber>
            <StyledDesktopNumberIcon className="phone-icon" />
            <div className="phone-icon"><FormattedMessage id="nav-bar.contact.phone" /></div>
          </StyledLink>
        )}
        {advisor?.email && (
          <StyledLink href={`mailto:${advisor?.email}`} rel="noopener noreferrer" target="_blank">
            <StyledDesktopMailIcon />
            <FormattedMessage id="nav-bar.contact.email" />
          </StyledLink>
        )}
        {advisor?.workPhoneNumber && (
          <StyledLink rel="noopener noreferrer" target="_blank">
            <PhoneNumber className="phone-number">{advisor?.workPhoneNumber}</PhoneNumber>
            <StyledDesktopSmsIcon className="sms-icon" />
            <div className="sms-icon"><FormattedMessage id="nav-bar.contact.sms" /></div>
          </StyledLink>
        )}
        {advisor?.line && (
          <StyledLink href={advisor.line} rel="noopener noreferrer" target="_blank">
            <StyledDesktopLineIcon />
            <FormattedMessage id="nav-bar.contact.line" />
          </StyledLink>
        )}
        {isProductDetailPage ? (
          <LastStyledLink href={productDetailed?.url || links.gucciSite} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="nav-bar.gucci-site" />
          </LastStyledLink>
        ) : (
          <WhiteLastStyledLink backgroundColor={colorUsage.burgundy} onClick={() => navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId }))}>
            <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
            <FormattedMessage id="nav-bar.favorites" />
          </WhiteLastStyledLink>
        )}
      </StyledNavBar>
    </DesktopContainer>
  );
};

export const MobileNavBar: React.FunctionComponent<Props> = ({
  caRecommendationId,
  isProductDetailPage = false
}) => {
  const navigate = useNavigate();
  const { advisor, products, recommendationType, } = useSelector(getCaRecommendation) || {};
  const { productStyleColorCode } = useParams<IProductDetailPage>();
  let productDetailed: Product | undefined;
  if (recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
    const caProducts = products as Product[];
    productDetailed = caProducts.find((product) => product.styleColorCode === productStyleColorCode);
  }
  if (recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
    const caProducts = products as LookbookProduct[];
    caProducts.forEach((product) => {
      if (productDetailed === undefined) {
        const findedProduct = product.products.find((p) => p.styleColorCode === productStyleColorCode);
        if (findedProduct) {
          productDetailed = findedProduct;
        }
      }
    });
  }
  const contactMethodNumber = Functions.countContactMethodNumber(advisor);
  const isMobileDevice = Functions.isMobileDevice();

  return (
    <MobileContainer>
      <StyledNavBar contactMethodNumber={contactMethodNumber}>
        <StyledLink onClick={() => navigate(`/suggestion/${caRecommendationId}?page=list`)}>
          <FormattedMessage id="nav-bar.advisor" />
        </StyledLink>
        {advisor?.whatsApp && (
          <StyledLink href={advisor?.whatsApp} rel="noopener noreferrer" target="_blank">
            <MobileWhatsAppIcon />
          </StyledLink>
        )}
        {advisor?.workPhoneNumber && (
          <StyledLink {...(isMobileDevice && { href: `tel:${advisor?.workPhoneNumber}` })} rel="noopener noreferrer" target="_blank">
            <MobileNumberIcon />
          </StyledLink>
        )}
        {advisor?.email && (
          <StyledLink href={`mailto:${advisor?.email}`} rel="noopener noreferrer" target="_blank">
            <MobileMailIcon />
          </StyledLink>
        )}
        {advisor?.workPhoneNumber && (
          <StyledLink {...(isMobileDevice && { href: `sms:${advisor?.workPhoneNumber}` })} rel="noopener noreferrer" target="_blank">
            <MobileSmsIcon />
          </StyledLink>
        )}
        {advisor?.line && (
          <StyledLink href={advisor.line} rel="noopener noreferrer" target="_blank">
            <MobileLineIcon />
          </StyledLink>
        )}
        {isProductDetailPage ? (
          <LastStyledLink href={productDetailed?.url || links.gucciSite} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="nav-bar.gucci-site" />
          </LastStyledLink>
        ) : (
          <LastStyledLink
            backgroundColor={colorUsage.burgundy}
            onClick={() => navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId }))}
          >
            <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
          </LastStyledLink>
        )}
      </StyledNavBar>
    </MobileContainer>
  );
};
