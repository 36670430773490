import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as NextArrow } from '@components/DesktopCarousel/arrow.svg';
import { ReactComponent as PrevArrow } from '@components/DesktopCarousel/prev-arrow.svg';
import { StyledCarousel } from '@components/DesktopCarousel/DesktopCarousel.style';

const SampleNextArrow = (props: any) => <NextArrow onClick={props.onClick} className={props.className} />;

const SamplePrevArrow = (props: any) => <PrevArrow onClick={props.onClick} className={props.className} />;

interface Props {
  children: React.ReactNode;
  setActivePicture: (index: number) => void;
}

const DesktopCarousel: React.FunctionComponent<Props> = ({ children, setActivePicture }) => {
  const hasToSlideToShowProducts = !!(children && (children as ReactNode[]).length > 4);
  const settings = {
    infinite: true,
    accessibility: true,
    vertical: false,
    focusOnSelect: true,
    slidesToShow: Math.min((children as ReactNode[]).length, 4),
    speed: hasToSlideToShowProducts ? 200 : 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current: number, next: number) => {
      setActivePicture(next);
    },
  };

  return (
    <StyledCarousel hasToSlideToShowProducts={hasToSlideToShowProducts}>
      <Slider {...settings}>{children}</Slider>
    </StyledCarousel>
  );
};

export default DesktopCarousel;
