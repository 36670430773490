import styled, { css } from 'styled-components';
import Close from '@components/CloseButton';
import theme from 'styled-theming';
import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing, lineHeight, onLargeScreen } from '@stylesheet';

export const Container = styled.div`
  position: relative;
  background-color: ${colorUsage.background};
  font-family: ${fontFamily.main};
  padding-bottom: ${getSpacing(3)};

  ${onLargeScreen(
    css`
      display: none;
    `,
  )}
`;

export const CloseButton = styled(Close)`
  position: fixed;
  z-index: 1;
  top: ${getSpacing(10)};
  right: ${getSpacing(3)};
`;

export const ProductInfoContainer = styled.div`
  position: relative;
  margin: ${getSpacing(3)} ${getSpacing(3)} 0 ${getSpacing(3)};
  padding: ${getSpacing(3)} ${getSpacing(4)};
  background-color: ${colorUsage.white};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.large};
  line-height: ${lineHeight.large};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
  margin: ${getSpacing(3)} 0 ${getSpacing(4)} 0;
  text-align: left;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Text = styled.div`
  ${sharedCss};
  margin-top: ${getSpacing(1)};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Slide = styled.div<{ windowWidth?: number }>`
  &:focus {
    outline: none;
  }

  img {
    width: ${(props) => (props.windowWidth ? `${props.windowWidth}px` : 'auto')};
  }
`;

export const Layout = styled.div<{ windowWidth?: number }>`
  /*
  * set height = width = 16px to add margin between carousel and product info container
  */
  height: ${(props) => props.windowWidth ? `calc(${props.windowWidth}px + ${getSpacing(2)})` : 'auto'};
  width: ${(props) => (props.windowWidth ? `${props.windowWidth}px` : 'auto')};
`;
