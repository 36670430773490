import styled, { css } from 'styled-components';

import { colorUsage, font, fontWeight, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import theme from 'styled-theming';

export const Container = styled.div`
  position: absolute;
  font-family: "guccisans", serif;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${onLargeScreen(css`
    display: flex;
    overflow: overlay;
    overflow: -moz-scrollbars-vertical;
  `)}
`;

export const Content = styled.div`
  background-color: ${colorUsage.white};
  height: 100%;
  width: 100%;
`;

export const TitleContainer = styled.div`
  font-weight: ${fontWeight.bold};
  padding: ${getSpacing(10)} ${getSpacing(6)} ${getSpacing(4)} ${getSpacing(1)};

  ${onLargeScreen(css`
    padding: ${getSpacing(10)} ${getSpacing(2)} ${getSpacing(4)} ${getSpacing(5)};
  `)}

  ${font.XLarge}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  color: white;
  ${onSmallScreen(css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
  `)}
  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 30px;
  `)}
`;
