import React, { useState } from 'react';

import DesktopCarousel from '@components/DesktopCarousel';
import { Product } from '@redux/caRecommendation/types';

import {
  CarouselAndImageContainer,
  Container,
  ImageAndSliderContainer,
  ProductDescription,
  ProductImage,
  ProductInfoContainer,
  ProductName,
  Slide,
  Text,
} from '@pages/ProductDetailPage/components/DesktopProductDetailPage/DesktopProductDetailPage.style';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { getPriceWithCurrency } from '@services/product/productService';

interface Props {
  product: Product;
}

const DesktopProductDetailPage: React.FunctionComponent<Props> = ({
  product
}) => {
  const [activePicture, setActivePicture] = useState(product.externalMediaUrls[0]);
  const language = useSelector(getLanguage)?.language.locale;

  return (
    <ThemeProvider theme={{ language }}>
      <Container>
        <CarouselAndImageContainer>
          <ImageAndSliderContainer>
            <ProductImage src={activePicture} alt="" />
            <DesktopCarousel
              setActivePicture={(index: number) => setActivePicture(product.externalMediaUrls[index])}
            >
              {product.externalMediaUrls.map((url) => (
                <Slide key={url}>
                  <img src={url} className="slide-children" alt="" />
                </Slide>
              ))}
            </DesktopCarousel>
          </ImageAndSliderContainer>
        </CarouselAndImageContainer>
        <ProductInfoContainer>
          <ProductName>{product.name}</ProductName>
          <ProductDescription>{product.description && product.description.replace(/;/g, '\n')}</ProductDescription>
          <Text>{product.styleColorCode}</Text>
          <Text>{getPriceWithCurrency(product.priceAmount, product.priceCurrency)}</Text>
        </ProductInfoContainer>
      </Container>
    </ThemeProvider>
  );
};

export default DesktopProductDetailPage;
