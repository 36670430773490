import styled, { css } from 'styled-components';
import { fontWeight, font, onLargeScreen, getSpacing, onSmallScreen, colorUsage } from '@stylesheet';
import { ReactComponent as LikeIcon } from '@assets/circle_heart.svg';
import LikeButton from '@components/LikeButton';
import theme from 'styled-theming';
import { textAnimationIn } from '@utils/animation';

const THUMBNAIL_CONTAINER_HEIGHT_IN_GREYBOX_MOBILE = getSpacing(6.5);
const THUMBNAIL_CONTAINER_HEIGHT_IN_GREYBOX_DESKTOP = getSpacing(18);
const THUMBNAIL_CONTAINER_MARGIN_LEFT_MOBILE = getSpacing(2);
const THUMBNAIL_CONTAINER_MARGIN_LEFT_DESKTOP = getSpacing(10);
const THUMBNAIL_CONTAINER_WIDTH_MOBILE = getSpacing(19);
const THUMBNAIL_CONTAINER_WIDTH_DESKTOP = getSpacing(29);

export const BackgroundContainer = styled.div`
  padding: ${getSpacing(2)} ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${colorUsage.background};
  aspect-ratio: 1;
  ${onLargeScreen(css`
    outline: 1px solid ${colorUsage.black};
    margin-top: 1px;
    margin-right: 1px;
    &:hover {
      .product-img {
        transition: transform 0.4s;
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
      }

      div, p {
        animation: ${textAnimationIn(colorUsage.background)} 0.3s linear 1 forwards;
      }
    }
  `)}
`;

export const Background = styled.img<{ windowWidth?: number }>`
  height: ${getSpacing(15)};
  width: ${getSpacing(15)};

  ${onLargeScreen(css`
    width: ${getSpacing(20)};
    height: ${getSpacing(20)};
  `)}
`;

export const IntroContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  overflow: hidden;
  ${onSmallScreen(css`
    grid-template-columns: auto;
  `)}

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`;

export const StyledMessages = styled.div`
  ${onLargeScreen(css`
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colorUsage.burgundy};
    color: ${colorUsage.white};
    padding: 0 90px;
    font-size: 27pt;
    outline: 1px solid ${colorUsage.black};
    margin-top: 1px;
    margin-right: 1px;
  `)}
  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const Hello = styled.div`
  ${onLargeScreen(css`
    line-height: 1.3;
  `)}
  text-align: start;
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const MySelection = styled.div`
  ${onLargeScreen(css`
    line-height: 1.3;
  `)}
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const Greeting = styled.div`
  ${onLargeScreen(css`
    line-height: 1.3;
  `)}  
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const LikedProductIcon = styled(LikeIcon)`
  width: ${getSpacing(3)};
  height: ${getSpacing(3)};
  position: absolute;
  top: ${getSpacing(3)};
  right: ${getSpacing(3)};
  cursor: pointer;
`;

export const TextContainer = styled.div<{ height: number }>`
  position: relative;
  ${font.XLarge};
  font-weight: ${fontWeight.bold};
  display: flex;
  align-items: center;
  padding-right: ${getSpacing(3)};
  border-right: 1px solid ${colorUsage.boxBorderColor};
  border-left: 1px solid ${colorUsage.boxBorderColor};

  ${(props) => onSmallScreen(css`
      height: calc(
        ${props.height}px - ${THUMBNAIL_CONTAINER_HEIGHT_IN_GREYBOX_MOBILE} + ${getSpacing(3)}
      );
      padding-left: calc(
        ${THUMBNAIL_CONTAINER_WIDTH_MOBILE} + ${THUMBNAIL_CONTAINER_MARGIN_LEFT_MOBILE} +
          ${getSpacing(3)}
      );
    `)}

  ${(props) => onLargeScreen(css`
      height: calc(
        ${props.height}px - ${THUMBNAIL_CONTAINER_HEIGHT_IN_GREYBOX_DESKTOP} + ${getSpacing(3)}
      );
      padding-left: calc(
        ${THUMBNAIL_CONTAINER_WIDTH_DESKTOP} + ${THUMBNAIL_CONTAINER_MARGIN_LEFT_DESKTOP} +
          ${getSpacing(3)}
      );
    `)}
`;

export const Favorite = styled(LikeButton)`
  position: absolute;
  bottom: ${getSpacing(2)};
  right: ${getSpacing(2)};

  ${onLargeScreen(css`
    bottom: ${getSpacing(4)};
    right: ${getSpacing(4)};
  `)}
`;
