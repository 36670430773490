import styled, { css } from 'styled-components';
import { getSpacing, onLargeScreen } from '@stylesheet';

export const Favorite = styled.img`
  height: ${getSpacing(2)};
  width: ${getSpacing(2)};
  ${onLargeScreen(css`
    height: ${getSpacing(3)};
    width: ${getSpacing(3)};
  `)}
  cursor: pointer;
`;
