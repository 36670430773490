import styled from 'styled-components';
import { ReactComponent as Close } from '@components/CloseButton/close.svg';
import { colorUsage, getSpacing } from '@stylesheet';

export const CloseIcon = styled(Close)`
  cursor: pointer;
  background-color: ${colorUsage.backgroundIcon};
  padding: ${getSpacing(0.5)};
  border-radius: 50px;
`;
