import React from 'react';
import { CARecommendation, Product as ProductType } from '@redux/caRecommendation/types';
import Product from '@components/Product';

interface Props {
  caRecommendation: CARecommendation,
  product: ProductType,
  index: number,
  goToProductDetail: (arg0: ProductType) => void
  isLookbook: boolean,
}

type ProductDisplayType = React.FC<Props>;

export const ProductDisplay: React.FC<Props> = ({ caRecommendation, product, index, goToProductDetail, isLookbook }) => (
  <GGProductDisplay
    caRecommendation={caRecommendation}
    product={product}
    index={index}
    goToProductDetail={goToProductDetail}
    isLookbook={isLookbook}
  />
);

const GGProductDisplay: ProductDisplayType = ({ caRecommendation, product, index, goToProductDetail, isLookbook }) => (
  <Product
    key={product.id}
    caRecommendation={caRecommendation}
    product={product}
    goToProductDetail={() => goToProductDetail(product)}
    isLookbook={isLookbook}
    index={index}
  />
);

export default ProductDisplay;
