import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CARecommendation, Product } from '@redux/caRecommendation/types';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import Functions from '@utils/Functions';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { Favorite, MainImage, Name, NameContainer } from '@components/Product/Product.style';
import {
  BackgroundContainer,
  Greeting,
  Hello,
  IntroContainer,
  MySelection,
  StyledMessages,
} from './Introduction.style';

interface Props {
  product: Product;
  caRecommendation: CARecommendation;
  goToProductDetail: () => void;
}

type SuggestionIntroductionType = React.FunctionComponent<Props>;

const Introduction: SuggestionIntroductionType = ({
  product,
  caRecommendation,
  goToProductDetail,
}) => (
  <GGIntroduction
    caRecommendation={caRecommendation}
    product={product}
    goToProductDetail={goToProductDetail}
  />
);

const GGIntroduction: SuggestionIntroductionType = ({
  product,
  caRecommendation,
  goToProductDetail,
}) => {
  const recommendation = useSelector(getCaRecommendation);
  const customer = recommendation?.clientDisplayName || recommendation?.clientLocalFirstName;
  const language = useSelector(getLanguage)?.language.locale;
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const customerGenderTranslation = caRecommendation?.clientGender === 'MALE' ? Functions.getGenderTranslation('MR') : Functions.getGenderTranslation(caRecommendation?.clientTitle);
  const isLiked = product.feedback && product.feedback >= 4;
  const isMySelectionGenderSpecific = language === 'ar';

  return (
    <ThemeProvider theme={{ language }}>
      <IntroContainer theme={brandConfiguration}>
        {caRecommendation?.greetings
          ? (
            <StyledMessages>
              <Hello>{caRecommendation.greetings.salutation}</Hello>
              <MySelection>{caRecommendation.greetings.body}</MySelection>
              <Greeting>
                {caRecommendation.greetings.signature}
                <br />
                {Functions.displayAdvisorName(caRecommendation.advisor)}
              </Greeting>
            </StyledMessages>
          )
          : (
            <StyledMessages>
              <Hello>
                {customerGenderTranslation && language !== 'kr' && language !== 'jp' && (
                <FormattedMessage
                  id={customerGenderTranslation}
                />
                )}
                {customer && (
                <FormattedMessage
                  id="product-list.hello"
                  values={{
                    clientFirstName: language === 'kr' ? Functions.toTitleCase(recommendation?.clientLocalFirstName) : Functions.toTitleCase(customer),
                    clientLastName: Functions.toTitleCase(recommendation?.clientDisplayName),
                    clientMiddleName: `${caRecommendation?.clientLocalMiddleName && caRecommendation?.clientLocalFirstName ? ' ' : ''}${Functions.toTitleCase(caRecommendation?.clientLocalMiddleName)}`,
                    linebreak: <br />
                  }}
                />
                )}
              </Hello>
              <MySelection>
                {isMySelectionGenderSpecific
                  ? (
                    <FormattedMessage
                      id={(caRecommendation?.clientGender !== undefined && caRecommendation?.clientGender !== 'MALE') ? 'product-list.my-selection.female' : 'product-list.my-selection.male'}
                      values={{
                        linebreak: <br />
                      }}
                    />
                  ) : <FormattedMessage id="product-list.my-selection" values={{ linebreak: <br /> }} />}
              </MySelection>
              <Greeting>
                <FormattedMessage
                  id="product-list.greeting"
                  values={{ linebreak: <br />, advisorName: Functions.displayAdvisorName(caRecommendation.advisor) }}
                />
              </Greeting>
            </StyledMessages>
          )}
        <BackgroundContainer onClick={goToProductDetail}>
          <MainImage src={product.externalMediaUrls[0]} alt="" className="product-img" />
          <NameContainer>
            <Name>{product.name}</Name>
          </NameContainer>
          {brandConfiguration.features.favorite && (
            <Favorite
              caRecommendationId={caRecommendation.id}
              styleColorCode={product.styleColorCode}
              initialIsLiked={!!isLiked}
            />
          )}
        </BackgroundContainer>
      </IntroContainer>
    </ThemeProvider>
  );
};

export default Introduction;
