import styled, { css } from 'styled-components';
import { colorUsage, onLargeScreen } from '@stylesheet';

export const Container = styled.div`
  position: absolute;
  font-family: "guccisans", serif;
  height: 100%;
  width: 100%;
  ${onLargeScreen(css`
    overflow: overlay;
    overflow: -moz-scrollbars-vertical;
  `)}
`;

export const Content = styled.div`
  background-color: ${colorUsage.white};
  margin-top: 50px;
  height: 100%;
  width: 100%;
`;
