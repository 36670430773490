import styled, { css } from 'styled-components';
import { colorUsage, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as DesktopWhatsAppIcon } from '@assets/whatApp/desktop.svg';
import { ReactComponent as DesktopNumberIcon } from '@assets/number/desktop.svg';
import { ReactComponent as DesktopMailIcon } from '@assets/mail/desktop.svg';
import { ReactComponent as DesktopSmsIcon } from '@assets/sms/desktop.svg';
import { ReactComponent as DesktopLineIcon } from '@assets/line/desktop.svg';

export const StyledNavBar = styled.div<{ contactMethodNumber?: number }>`
  position: fixed;
  width: 100%;
  display: grid;
  z-index: 1;
  top: 0;
  height: 50px;
  grid-template-columns: 4fr repeat(${(props) => props.contactMethodNumber}, 1.5fr) 2fr;
  grid-auto-flow: column;
  background-color: white;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorUsage.black};
  ${onLargeScreen(css`
    grid-auto-columns: 1fr;
  `)};
`;

export const DesktopContainer = styled.div`
  overflow: hidden;
  z-index: 1;

  ${onSmallScreen(css`
    display: none
  `)}
`;

export const MobileContainer = styled.div`
  ${onLargeScreen(css`
    display: none
  `)}
`;

export const StyledLink = styled.a<{
  backgroundColor?: string;
}>`
  color: black;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 9pt;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  ${onLargeScreen(css`
    box-shadow: -1px 0 0 ${colorUsage.black} inset;
  `)}
  ${onSmallScreen(css`
    font-size: 7pt;
  `)}
  &:hover {
    .phone-number {
      display: flex;
    }
    .phone-icon, .sms-icon {
      display: none;
    }
  }
`;

export const LastStyledLink = styled(StyledLink)`
  box-shadow: 0 0 0 1px ${colorUsage.black};
`;

export const WhiteLastStyledLink = styled(StyledLink)`
  box-shadow: 0 0 0 1px ${colorUsage.black};
  color: ${colorUsage.white};
`;

export const StyledMyFavorites = styled.img`
  ${onLargeScreen(css`
    margin-right: 10px;
    width: ${getSpacing(2)};
  `)}
`;

export const PhoneNumber = styled.div`
  display: none;
`;

export const StyledDesktopNumberIcon = styled(DesktopNumberIcon)`
  margin-left: -30px;
`;

export const StyledDesktopWhatsAppIcon = styled(DesktopWhatsAppIcon)`
  margin-left: -30px;
`;

export const StyledDesktopMailIcon = styled(DesktopMailIcon)`
  margin-left: -30px;
`;

export const StyledDesktopSmsIcon = styled(DesktopSmsIcon)`
  margin-left: -30px;
`;

export const StyledDesktopLineIcon = styled(DesktopLineIcon)`
  margin-left: -30px;
`;
