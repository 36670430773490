import styled, { css } from 'styled-components';
import { font, fontFamily, fontSize, fontWeight, getSpacing, lineHeight, onSmallScreen } from '@stylesheet';

import Close from '@components/CloseButton';
import theme from 'styled-theming';

export const Container = styled.div`
  max-width: ${getSpacing(200)};
  margin: 0 auto;
  padding: 0 ${getSpacing(2)};
  display: flex;
  font-family: ${fontFamily.main};

  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const CarouselAndImageContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
`;

export const ImageAndSliderContainer = styled.div`
  margin-right: ${getSpacing(4)};
`;

export const ProductImage = styled.img`
  width: ${getSpacing(70)};
  min-width: ${getSpacing(70)};
  height: ${getSpacing(70)};
`;

export const ProductInfoContainer = styled.div`
  margin-top: 50px
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  ${font.XLarge};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
  margin: ${getSpacing(3)} 0 ${getSpacing(5)} 0;
  text-align: justify;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Text = styled.div`
  ${sharedCss};
  margin-top: ${getSpacing(1)};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Slide = styled.div`
  margin-bottom: ${getSpacing(0.5)};
  .slide-children {
    width: ${getSpacing(18)};
    min-width: ${getSpacing(18)};
    height: ${getSpacing(18)};
    box-sizing: border-box;
    box-shadow: 0 0 0 2px white;
  }
  &:focus {
    outline: none;
  }
`;

export const CloseButton = styled(Close)`
  position: absolute;
  top: ${getSpacing(12)};
  left: ${getSpacing(2)};
  cursor: pointer;
`;
