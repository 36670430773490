import React from 'react';

import MobileCarousel from '@components/MobileCarousel';
import { useWindowSize } from '@services/hooks/useWindowSize';
import { Product } from '@redux/caRecommendation/types';

import {
  Container,
  Layout,
  ProductDescription,
  ProductInfoContainer,
  ProductName,
  Slide,
  Text,
} from '@pages/ProductDetailPage/components/MobileProductDetailPage/MobileProductDetailPage.style';
import { ThemeProvider } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { getPriceWithCurrency } from '@services/product/productService';

interface Props {
  product: Product;
}

const MobileProductDetailPage: React.FunctionComponent<Props> = ({
  product,
}) => {
  const [windowSize] = useWindowSize();
  const language = useSelector(getLanguage)?.language.locale;

  return (
    <ThemeProvider theme={{ language }}>
      <Container>
        <MobileCarousel windowWidth={windowSize.width}>
          {product.externalMediaUrls.map((url) => (
            <Slide key={url} windowWidth={windowSize.width}>
              <img src={url} alt="product" />
            </Slide>
          ))}
        </MobileCarousel>
        <Layout windowWidth={windowSize.width} />
        <ProductInfoContainer>
          <ProductName>{product.name}</ProductName>
          <ProductDescription>{product.description && product.description.replace(/;/g, '\n')}</ProductDescription>
          <Text>{product.styleColorCode}</Text>
          <Text>{getPriceWithCurrency(product.priceAmount, product.priceCurrency)}</Text>
        </ProductInfoContainer>
      </Container>
    </ThemeProvider>
  );
};

export default MobileProductDetailPage;
