import React from 'react';

import {
  CARecommendation,
  LookbookProduct,
  Product,
  Product as ProductType,
  RECOMMENDATION_TYPE
} from '@redux/caRecommendation/types';

import { PATHS } from '@routes';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { generatePath, useNavigate } from 'react-router-dom';
import ProductDisplay from '@pages/Suggestion/components/ProductList/components/ProductDisplay/ProductDisplay';
import Introduction from '@pages/Suggestion/components/ProductList/components/Introduction';
import {
  Background,
  Container,
  ProductsContainer,
  ProductsLookbookChapter
} from '@pages/Suggestion/components/ProductList/ProductList.style';

interface Props {
  caRecommendation: CARecommendation;
}

const ProductList: React.FC<Props> = ({ caRecommendation }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const navigate = useNavigate();
  const { recommendationType } = caRecommendation;

  const goToProductDetail = (product?: ProductType) => {
    if (product) {
      if (brandConfiguration.features.productDetail) {
        navigate('?page=list');
        navigate(
          generatePath(PATHS.PRODUCT_DETAIL, {
            recommendationId: caRecommendation.id,
            productStyleColorCode: product.styleColorCode,
          }),
        );
      } else {
        window.location.href = brandConfiguration.constants.url;
      }
    }
  };

  let mainProduct: Product | undefined;
  let otherProducts: Product[] | undefined;
  let lookbookProducts: LookbookProduct[] | undefined;
  if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
    const products = caRecommendation.products as Product[];
    const [mainProductTemp, ...otherProductsTemp] = products;
    mainProduct = mainProductTemp;
    otherProducts = otherProductsTemp;
  }
  if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
    lookbookProducts = caRecommendation.products as LookbookProduct[];
  }

  return (
    <Background>
      <Container>
        {recommendationType === RECOMMENDATION_TYPE.CURATED_LIST && mainProduct !== undefined && (
          <Introduction
            caRecommendation={caRecommendation}
            product={mainProduct}
            goToProductDetail={() => goToProductDetail(mainProduct)}
          />
        )}
        {recommendationType === RECOMMENDATION_TYPE.LOOKBOOK ? (
          lookbookProducts?.map((lookbookProduct, indexRow) => (
            <>
              {!((lookbookProduct?.chapterName === undefined || lookbookProduct?.chapterName === '') && indexRow === 0) && (
                <ProductsLookbookChapter>
                  {lookbookProduct?.chapterName}
                </ProductsLookbookChapter>
              )}
              <ProductsContainer theme={brandConfiguration}>
                {lookbookProduct?.products?.map((product, index) => (
                  <ProductDisplay
                    key={product.id}
                    caRecommendation={caRecommendation}
                    product={product}
                    index={index}
                    goToProductDetail={() => goToProductDetail(product)}
                    isLookbook
                  />
                ))}
              </ProductsContainer>
            </>
          ))) : (
            <ProductsContainer theme={brandConfiguration}>
              {otherProducts && otherProducts.map((product, index) => (
                <ProductDisplay
                  key={product.id}
                  caRecommendation={caRecommendation}
                  product={product}
                  index={index}
                  goToProductDetail={() => goToProductDetail(product)}
                  isLookbook={false}
                />
              ))}
            </ProductsContainer>
        )}
      </Container>
    </Background>
  );
};

export default ProductList;
