import React, { ReactNode, useState } from 'react';
import Slider from 'react-slick';
import { StyledCarousel } from './MobileCarousel.style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
  children: React.ReactNode;
  windowWidth?: number;
}

type Item = React.ReactNode & { props: { className?: string } };

const MobileCarousel: React.FC<Props> = ({ children, windowWidth }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const numberOfSlides = (children as ReactNode[])?.length || 0;

  const getStyle = () => {
    /*
     * Every time the active index is a multiple of 3 (after a click on a small bullet),
     * we need to move all the bullets to the left and hide the previous 3 main bullets
     * The first time we only need to hide 2 big bullets, then we need to hide 2 big bullets + 1 small bullet
     */
    const offset = Math.floor(activeIndex / 3);
    const smallBulletWidth = 14;
    const bigBulletWidth = 16;
    const firstMultiplier = -(2 * bigBulletWidth);
    const secondMultiplier = -(2 * bigBulletWidth + smallBulletWidth);
    return {
      transform:
        offset === 0
          ? 'translateX(0)'
          : `translateX(${secondMultiplier * (offset - 1) + firstMultiplier}px)`,
    };
  };

  const getClassName = (item: Item, index: number) => {
    /*
     * The indexes of the small bullets on the right are always divisible by 3 and > 0.
     * The indexes of the small bullets on the left fulfill the condition x % 3 = 2 <=> (x + 1) % 3 = 0, and must be > 0.
     * Moreover, a bullet shouldn't be small if
     * - it is active
     * - it is the last bullet
     * - its index is in the current window
     */
    const classname = item.props.className;
    const baseCondition = index > 1 && !item.props.className.includes('slick-active');
    const smallRight = baseCondition && (index + 1) % 3 === 0 && index < activeIndex;
    const smallLeft = baseCondition && index % 3 === 0 && index !== numberOfSlides && index > activeIndex;
    if (smallRight || smallLeft) {
      return `${classname} small`;
    }
    return classname;
  };
  const settings = {
    accessibility: true,
    dots: true,
    slidesToScroll: 1,
    infinite: false,
    slidesToShow: 1,
    customPaging: () => (
      <div>
        <button className="dots-button" type="button" aria-label="dots-button" />
      </div>
    ),
    appendDots: (dots: Item[]) => (
      <div>
        <ul>
          {dots.map((item, index) => {
            if (item.props.className.includes('slick-active')) {
              setActiveIndex(index);
            }
            return (
              <li key={index} className={getClassName(item, index)} style={getStyle()}>
                {item.props.children}
              </li>
            );
          })}
        </ul>
      </div>
    ),
  };

  return (
    <StyledCarousel activeIndex={activeIndex} width={windowWidth}>
      <Slider {...settings}>{children}</Slider>
    </StyledCarousel>
  );
};

export default MobileCarousel;
