import styled from 'styled-components';
import { getSpacing } from '@stylesheet';

export const StyledCarousel = styled.div<{
  hasToSlideToShowProducts: boolean
}>`
  margin-right: ${getSpacing(1)};
  .slick-slider {
    width: ${getSpacing(70)};
    min-width: ${getSpacing(70)}; 
    .slick-slide {
      width: ${getSpacing(17.5)} !important;
      box-sizing: border-box;
      border: none;
    }
  }

  .slick-next {
    top: ${getSpacing(-40)};
    right: ${getSpacing(2)};
  }

  .slick-prev {
    top: ${getSpacing(-40)};
    left: ${getSpacing(2)};
  }
`;
