import styled from 'styled-components';
import { getSpacing, colorUsage } from '@stylesheet';

export const StyledCarousel = styled.div<{ activeIndex: number; width?: number }>`
  position: fixed;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};

  .slick-dots {
    /*
    * Set dots 16px under the bottom of the image to avoid dots to be over it.
    * Otherwise sometimes the dots won't be visible enough.
    */
    bottom: ${getSpacing(-2)};
    margin: 0 auto;

    ul {
      width: ${(props) => (props.activeIndex > 2 ? '78' : '62')}px;
      height: 1rem;
      overflow: hidden;
      margin: auto;
      display: flex;
      align-items: center;
    }

    ul > li {
      all: unset;
      display: inline-block;
      margin: ${getSpacing(0.5)};
      width: ${getSpacing(1)};
      height: ${getSpacing(1)};
      border: 1px solid ${colorUsage.textGrey};
      border-radius: 100%;
      box-sizing: border-box;

      &.slick-active {
        background-color: ${colorUsage.textGrey};
      }

      &.small {
        width: ${getSpacing(0.75)};
        height: ${getSpacing(0.75)};
      }
    }
  }

  .dots-button {
    visibility: hidden;
  }
`;
