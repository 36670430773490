import styled, { css } from 'styled-components';
import { onLargeScreen } from '@stylesheet';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  font-family: "guccisans", serif;
  ${onLargeScreen(css`
    overflow: overlay;
    overflow: -moz-scrollbars-vertical;
  `)}
`;
