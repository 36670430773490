import React from 'react';
import { CloseIcon } from '@components/CloseButton/CloseButton.style';

interface Props {
  onClick?: () => void;
  className?: string;
}

const CloseButton: React.FunctionComponent<Props> = ({ onClick, className }) => <CloseIcon className={className} onClick={onClick} />;

export default CloseButton;
