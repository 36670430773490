import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useSelector from '@redux/useSelector';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { LookbookProduct, Product as ProductType, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import Loader from '@components/Loader';
import Product from '@components/Product';

import { Container, Content, ProductsContainer, TitleContainer } from '@pages/LikedProducts/LikedProducts.style';
import { PATHS } from '@routes';
import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';

type ILikedProducts = {
  caRecommendationId: string;
}

const LikedProducts: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { caRecommendationId } = useParams<ILikedProducts>();
  const [, fetchCaRecommendation] = useFetchCaRecommendation();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);
  const language = useSelector(getLanguage)?.language.locale;
  const [likedProducts, setLikedProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    if (caRecommendation) {
      let likeProducts: ProductType[] = [];
      if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
        const products = caRecommendation.products as ProductType[];
        likeProducts = products.filter(
          (product) => product.feedback && product.feedback >= 4,
        );
      }
      if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
        const products = caRecommendation.products as LookbookProduct[];
        products.forEach((product) => { likeProducts = [...likeProducts, ...(product.products.filter((p) => p.feedback && p.feedback >= 4))]; });
      }
      setLikedProducts(likeProducts);
    }
  }, [caRecommendation]);

  useEffect(() => {
    (async function fetch() {
      if (caRecommendationId) {
        await fetchCaRecommendation(caRecommendationId);
      }
    }());
  }, [caRecommendationId, fetchCaRecommendation]);

  if (!caRecommendation) {
    return <Loader />;
  }

  /* todo refacto to use only recommendationId or caRecommendationId everywhere */
  const goToProductDetail = (product: ProductType) => {
    navigate(
      generatePath(PATHS.PRODUCT_DETAIL, {
        caRecommendationId: caRecommendation.id,
        recommendationId: caRecommendation.id,
        productStyleColorCode: product.styleColorCode,
      }),
    );
  };

  return (
    <ThemeProvider theme={{ language }}>
      <Container>
        <DesktopNavBar caRecommendationId={caRecommendation.id} />
        <MobileNavBar caRecommendationId={caRecommendation.id} />
        <Content>
          <TitleContainer>
            <FormattedMessage
              id={likedProducts.length ? 'liked-products.title' : 'liked-products.no-products'}
              values={{ linebreak: <br /> }}
            />
          </TitleContainer>
          <ProductsContainer theme={brandConfiguration}>
            {likedProducts.map((product) => (
              <Product
                key={product.id}
                caRecommendation={caRecommendation}
                product={product}
                goToProductDetail={() => goToProductDetail(product)}
                isLookbook={false}
              />
            ))}
          </ProductsContainer>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default LikedProducts;
