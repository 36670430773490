import { PublicAdvisor } from '@redux/advisors/types';

export default class Functions {
  static toTitleCase(text?: string): string {
    if (!text) return '';
    const compoundWords = text.split('-');
    const uppercaseWord = compoundWords.map((compoundWord) => {
      if (!compoundWord) return '';
      return compoundWord[0].toUpperCase() + compoundWord.substring(1).toLowerCase();
    });
    return uppercaseWord.join('-');
  }

  static isDesktopVersion = () => window.innerWidth > 1023;

  static isMobileDevice = () => (/android/i.test(navigator.userAgent)) || (/iPhone/i.test(navigator.userAgent));

  static countContactMethodNumber = (advisor?: PublicAdvisor) => {
    let number = 0;
    if (!advisor) {
      return 0;
    }
    if (advisor.email) {
      number += 1;
    }
    if (advisor.workPhoneNumber) {
      number += 2;
    }
    if (advisor.whatsApp) {
      number += 1;
    }
    return number;
  };

  static displayAdvisorName = (advisor?: PublicAdvisor) => advisor?.displayName || advisor?.firstName;

  static getGenderTranslation(gender?: string) {
    if (gender === 'MR') {
      return 'gender-mr';
    }
    if (gender === 'MS') {
      return 'gender-ms';
    }
    if (gender === 'MRS') {
      return 'gender-mrs';
    }
    if (gender === 'MISS') {
      return 'gender-miss';
    }
    return '';
  }
}
